import Container from '@components/Container';
import styled from '@emotion/styled';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import { useNavigate } from 'react-router';

import DiscordLogo from '@images/discord.svg';
import FacingsLogo from '@images/FACINGS.svg';
import TelegramLogo from '@images/telegram-icon.svg';
import TwitterLogo from '@images/twitter-icon.svg';

const StyledFooter = styled.div(({ theme, style }) => ({
    backgroundColor: theme.colors.secondary.main,
    padding: theme.spacing.s,
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing.xs,
    ...style
}));

const Row = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.xs
}));

const SocialMediaLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    margin: theme.spacing.xxs
}));

const FacingsGroup = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FooterLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    userSelect: 'none'
}));

const TermsLink = styled.p(({ theme }) => ({
    cursor: 'pointer',
    ...theme.typography.p,
    color: theme.colors.common.black
}));

function Footer({ style }) {
    const history = useNavigate();

    const goToTerms = () => {
        history.push(ROUTES.terms);
        window.scrollTo(0, 0);
    };

    return (
        <StyledFooter>
            <Container justifyContent='space-evenly'>
                <FacingsGroup>
                    <FooterLink
                        href={RES.externalLinks.soundr.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img
                            style={{ width: '100px' }}
                            src={RES.images.soundrLogo}
                            alt={RES.externalLinks.soundr.label}
                        />
                    </FooterLink>
                    <Row>
                        <SocialMediaLink
                            href={RES.externalLinks.skullzTwitter.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img
                                style={{ width: '24px' }}
                                src={TwitterLogo}
                                alt={RES.externalLinks.skullzTwitter.label}
                            />
                        </SocialMediaLink>
                    </Row>
                </FacingsGroup>

                {/* <FooterLink href={RES.externalLinks.wax.link} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '150px' }} src={PoweredByWax}  alt={RES.externalLinks.wax.label} />
                    </FooterLink>
                    <FooterLink href={RES.externalLinks.atomicAssets.link} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '150px' }} src={AtomicAssetsLogo} alt={RES.externalLinks.atomicAssets.label} />
                    </FooterLink> */}
                <FacingsGroup>
                    <FooterLink
                        href={RES.externalLinks.facings.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img
                            style={{ width: '70px', margin: '0 25px' }}
                            src={FacingsLogo}
                            alt={RES.externalLinks.facings.label}
                        />
                    </FooterLink>
                    <Row>
                        <SocialMediaLink
                            href={RES.externalLinks.facingsTelegram.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img
                                style={{ width: '24px' }}
                                src={TelegramLogo}
                                alt={RES.externalLinks.facingsTelegram.label}
                            />
                        </SocialMediaLink>
                        <SocialMediaLink
                            href={RES.externalLinks.facingsTwitter.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img
                                style={{ width: '24px' }}
                                src={TwitterLogo}
                                alt={RES.externalLinks.facingsTwitter.label}
                            />
                        </SocialMediaLink>
                        <SocialMediaLink
                            href={RES.externalLinks.facingsDiscord.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img
                                style={{ width: '24px' }}
                                src={DiscordLogo}
                                alt={RES.externalLinks.facingsDiscord.label}
                            />
                        </SocialMediaLink>
                    </Row>
                </FacingsGroup>
            </Container>
            <TermsLink onClick={() => goToTerms()}>
                {STRINGS.termsPage.termsAndConditions}
            </TermsLink>
        </StyledFooter>
    );
}

export default Footer;
