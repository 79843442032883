import styled from '@emotion/styled';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer';

import WithRouter from '@components/WithRouter';

import Craft from '@context/Craft';
import FAQ from '@context/FAQ';
import Home from '@context/Home';
import Inventory from '@context/Inventory';
import NotFound from '@context/NotFound';
import PackRips from '@context/PackRips';
import Resources from '@context/Resources';
import BuyPacks from '@context/Shop/BuyPacks';
import Terms from '@context/Terms';
import {
    FIAT_PAYMENT_ENABLED,
    SHOW_BUY_PAGE,
    SHOW_CRAFT,
    SHOW_FAQ,
    SHOW_HEADER,
    SHOW_PACK_HISTORY,
    WAX_SIGN_IN
} from '@utils/globals';
import ROUTES from '@utils/routes';

import FiatHistory from '@components/Fiat/FiatHistory';

const Container = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: `calc(100vh - ${theme.size.headerHeight} - ${theme.size.footerHeight})`,
    margin: SHOW_HEADER ? `${theme.size.headerHeight} 0px 0px 0px` : 0
}));

function Content({ ual, location, history }) {
    const navigate = useNavigate();

    if (WAX_SIGN_IN && ual && !ual.activeUser) {
        if (
            location &&
      (location.pathname.includes(ROUTES.inventory) ||
        location.pathname.includes(ROUTES.craft) ||
        location.pathname.includes(ROUTES.view))
        ) {
            navigate(ROUTES.home);
        }
    }

    return (
        <Container>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path={ROUTES.home} element={<Home />} />
                {SHOW_BUY_PAGE ? (
                    <Route path={ROUTES.buypacks} element={<BuyPacks />} />
                ) : null}
                {SHOW_FAQ ? <Route path={ROUTES.faq} element={<FAQ />} /> : null}
                {WAX_SIGN_IN ? (
                    <Route path={ROUTES.inventory} element={<Inventory />} />
                ) : null}
                {WAX_SIGN_IN && SHOW_PACK_HISTORY ? (
                    <Route path={ROUTES.packrips} element={<PackRips />} />
                ) : null}
                {WAX_SIGN_IN && SHOW_CRAFT ? (
                    <Route path={ROUTES.craft} element={<Craft />} />
                ) : null}
                {WAX_SIGN_IN ? (
                    <Route path={ROUTES.resources} element={<Resources />} />
                ) : null}
                <Route path={ROUTES.terms} element={<Terms />} />
                {FIAT_PAYMENT_ENABLED && SHOW_BUY_PAGE ? (
                    <Route path={ROUTES.paymenthistory} element={<FiatHistory />} />
                ) : null}
                <Route path={ROUTES.notfound} element={<NotFound />} />
                <Route path={ROUTES.notfound} />
            </Routes>
        </Container>
    );
}

export default WAX_SIGN_IN ? WithRouter(withUAL(Content)) : WithRouter(Content);
